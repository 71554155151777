$(()=> {
    $(".js-range-slider").ionRangeSlider({
		onChange: function(data) {
			if($('input[name="price_start"]').length) {
				$('input[name="price_start"]').val(data.from)
			};
			if($('input[name="price_end"]').length) {
				$('input[name="price_end"]').val(data.to)
			};
		}
	})
})